import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { api_url } from "../utils/constants";
import { useUserContext } from "../context/user_context";

const SignInContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, hsl(20, 31%, 74%), hsl(22, 31%, 52%));
`;

const SignInBox = styled.div`
  margin: 0 2px;
  width: 100%;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding: 30px;
  padding-bottom: 60px;
  border-radius: 15px;
  max-width: 620px;
  padding: 60px;
`;

const SignInButton = styled.button`
  margin-top: 2px;
  width: 100%;
  border-radius: 15px;
  background-color: hsl(22, 31%, 52%);
  padding: 12px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: hsl(22, 31%, 56%);
    cursor: pointer;
  }
  &:active {
    background-color: hsl(22, 31%, 56%);
  }
  border: none;
`;

const InputLabel = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  margin-right: auto;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const SignUpLink = styled(Link)`
  display: block;
  margin-top: 1rem;
  text-align: center;
  color: hsl(22, 31%, 52%);
  text-decoration: none;
`;

export default function LoginPage() {
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    const res = await axios.post(`${api_url}/user/login`, {
      email,
      password,
    });
    if (res?.data.message) {
      setEmail("");
      setPassword("");
    }
    const user = res.data.data;
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      navigate("/");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  return (
    <SignInContainer>
      <SignInBox>
        <h4>Sign In</h4>
        <p style={{ marginBottom: "60px" }}>
          Enter your email and password to sign in!
        </p>
        <InputLabel htmlFor="email">Email</InputLabel>
        <InputField
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          placeholder="email@mail.com"
        />
        <InputLabel htmlFor="password">Password</InputLabel>
        <InputField
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          name="password"
          placeholder="****"
        />
        <SignInButton onClick={handleSubmit}>Sign In</SignInButton>
        <SignUpLink to="/signup">Don't have an account? Sign Up</SignUpLink>
      </SignInBox>
    </SignInContainer>
  );
}
