import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { categories } from "../utils/constants";
const Categories = () => {
  return (
    <Wrapper>
      <div className="section-center">
        <h2 className="header">Categories</h2>
        <div className="services-center">
          {categories.map((category) => {
            const { id, image, title, description, link } = category;
            return (
              <Link
                to={{
                  pathname: "/products",
                }}
                state={{ category: link }}
                key={id}
              >
                <article className="service">
                  <img src={image} alt={title} className="icon" />
                  <div>
                    <h4>{title}</h4>
                    <p>{description}</p>
                  </div>
                </article>
              </Link>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  h2,
  h4 {
    color: var(--clr-primary-1);
  }
  padding: 30px 0;

  background: var(--clr-primary-10);

  .header {
    padding-top: 2rem;
    width: 100%;
    text-align: center;
  }
  p {
    margin-bottom: 0;
    line-height: 1.8;
    color: var(--clr-primary-3);
  }
  .services-center {
    margin-top: 2rem;
    display: grid;
    gap: 2.5rem;
    padding: 2rem 0;
  }
  .service {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background: var(--clr-primary-7);
    text-align: center;
    //padding: 2.5rem 2rem;
    border-radius: var(--radius);
    p {
      color: var(--clr-primary-2);
    }
    h4 {
      font-size: 1.5rem;
    }
    img {
      width: 100%;
      height: 200px;
    }
    div {
      padding: 2rem;
    }
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      background: var(--clr-primary-6);
    }
  }
  span {
    width: 4rem;
    height: 4rem;
    display: grid;
    margin: 0 auto;
    place-items: center;
    margin-bottom: 1rem;
    border-radius: 50%;
    background: var(--clr-primary-10);
    color: var(--clr-primary-1);
    svg {
      font-size: 2rem;
    }
  }
  @media (min-width: 576px) {
    .services-center {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`;
export default Categories;
