import React, { useEffect } from "react";
import { FeaturedProducts, Hero, Services, Contact } from "../components";
import Categories from "../components/Categories";
const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <Hero />
      <Categories />
      <FeaturedProducts />
      <Services />
      <Contact />
    </main>
  );
};

export default HomePage;
