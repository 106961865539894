import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <LogoSection>
          <h4
            style={{
              color: "#102A42",
              fontFamily: "fantasy",
              marginBottom: 15,
            }}
          >
            UrbanNest LLC
          </h4>
          <Description>
            We are a wellness-centric company, passionately dedicated to
            fostering holistic lifestyles through our thoughtfully curated
            selection of premium products.
          </Description>
        </LogoSection>
        <LinksSection>
          <Title>Useful links</Title>
          <Link to={"/"}>Home</Link>
          <Link to={"/products"}>Products</Link>
          <Link to={"/about"}>About Us</Link>
        </LinksSection>
        <ContactSection>
          <Title>Contact Us</Title>
          <ContactItem>
            <Icon src={"/mail.svg"} alt="mail" />
            <p>info@urbannestllcwy.com</p>
          </ContactItem>
          <ContactItem>
            <Icon src={"/phone.svg"} alt="phone" />
            <p> +1(307)393-9854</p>
          </ContactItem>
          <ContactItem>
            <Icon src={"/map-pin.svg"} alt="map" />
            <p>30 N GOULD ST STE 42340 SHERIDAN, WY 82801</p>
          </ContactItem>
        </ContactSection>
      </FooterContainer>
      <PrivacyInfo>
        <h5>
          &copy; {new Date().getFullYear()}
          <span> UrbanNest LLC </span>
        </h5>
        <h5>All rights reserved</h5>
      </PrivacyInfo>
    </>
  );
};

const FooterContainer = styled.div`
  margin: 0 10%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1px;
  border-top: 1px solid #d1d5db;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 30%;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Description = styled.p`
  color: #939393;
`;

const LinksSection = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 16.66667%;
  a {
    color: #707070;
    &:hover {
      color: #102a42;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const ContactSection = styled(LinksSection)``;

const Title = styled.h4`
  color: #707070;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
`;

const ContactItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: flex-start;

  p {
    margin: 0;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  padding-top: 4px;
`;

const PrivacyInfo = styled.div`
  height: 5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--clr-black);
  text-align: center;
  span {
    color: var(--clr-primary-5);
  }
  h5 {
    color: var(--clr-white);
    margin: 0.1rem;

    font-weight: 400;
    text-transform: none;
    line-height: 1.25;
  }
`;

export default Footer;
