import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { Filters, ProductList, Sort, PageHero } from "../components";
const ProductsPage = () => {
  const location = useLocation();
  const category = location.state?.category;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <PageHero title="products" />
      <Wrapper className="page">
        <div className="section-center products">
          <Filters />
          <div>
            <Sort />
            <ProductList category={category} />
          </div>
        </div>
      </Wrapper>
    </main>
  );
};

const Wrapper = styled.div`
  .products {
    display: grid;
    gap: 3rem 1.5rem;
    margin: 4rem auto;
  }
  @media (min-width: 768px) {
    .products {
      grid-template-columns: 200px 1fr;
    }
  }
`;

export default ProductsPage;
