import React from "react";
import { GiCompass, GiDiamondHard, GiStabbedNote } from "react-icons/gi";
export const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about",
  },
  {
    id: 3,
    text: "products",
    url: "/products",
  },
];

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: "mission",
    text: "Our mission at Urban Nest LLC is to curate diverse ranges of Beauty, Health, and Baby essentials, promoting conscious living for all. ",
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: "vision",
    text: "Urban Nest LLC envisions a world where self-care is embraced by all, fostering communities empowered by access to sustainable products.",
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: "history",
    text: "Urban Nest LLC was born from a passion for holistic well-being and sustainability. From humble beginnings, we've grown into a trusted source for premium essentials. ",
  },
];

export const categories = [
  {
    id: 1,
    title: "Beauty",
    link: "Beauty & Personal Care",

    description:
      "Our Beauty collection features a wide range of natural and organic products to elevate your skincare routine.",
    image: "/beauty.jpeg",
  },
  {
    id: 2,
    title: " Household",
    link: "Health & Household",
    description:
      "Our Health collection offers a variety of supplements and vitamins to support your overall well-being.",
    image: "/household.jpeg",
  },
  {
    id: 3,
    title: "Baby",
    link: "Baby Products",
    description:
      "Our Baby collection is filled with gentle products to care for your little one, from bath time to bedtime.",
    image: "/baby.jpeg",
  },
];

export const products_url = "https://ecomm-backend-0h0p.onrender.com/item";

export const single_product_url = `https://ecomm-backend-0h0p.onrender.com/item`;

export const api_url = "https://ecomm-backend-0h0p.onrender.com";
