import React, { useEffect } from "react";
import styled from "styled-components";
import { PageHero } from "../components";
import aboutImg from "../assets/hero-bcg.jpeg";
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <PageHero title="about" />
      <Wrapper className="page section section-center">
        <img src={aboutImg} alt="nice desk" />
        <article>
          <div className="title">
            <h2>our story</h2>
            <div className="underline"></div>
          </div>
          <p>
            Urban Nest LLC, a beacon of modern convenience and care, embraces
            the ethos of nurturing holistic wellness in every aspect of life.
            Specializing in three fundamental categories - Beauty and Personal
            Care, Health and Household, and Baby essentials - Urban Nest offers
            a curated selection of products designed to elevate daily routines
            into rituals of self-care, health, and comfort.
          </p>
          <p>
            At Urban Nest, we understand that wellness is not a luxury but a
            necessity. Our Beauty and Personal Care collection boasts an array
            of premium skincare, haircare, and grooming products sourced from
            trusted brands that prioritize natural ingredients and sustainable
            practices. Whether it's a luxurious moisturizer to indulge your skin
            or a rejuvenating hair mask to restore vitality, our selection
            caters to every individual's unique needs and preferences.
          </p>
        </article>
      </Wrapper>
    </main>
  );
};
const Wrapper = styled.section`
  display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }
  p {
    line-height: 2;
    max-width: 45em;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--clr-grey-5);
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export default AboutPage;
