import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PageHero, StripeCheckout } from "../components";

// extra imports

import { useCartContext } from "../context/cart_context";
import { Link } from "react-router-dom";

const CheckoutPage = () => {
  const { cart } = useCartContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <PageHero title="checkout" />
      <Wrapper className="page">
        {cart.length < 1 ? (
          <div className="empty">
            <h2>Your cart is empty</h2>
            <Link to="/products" className="btn">
              fill it
            </Link>
          </div>
        ) : (
          <CheckoutWrapper>
            <UserDetails />
            <StripeCheckout />
          </CheckoutWrapper>
        )}
      </Wrapper>
    </main>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .empty {
    text-align: center;
  }
`;
const InputLabel = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  margin-right: auto;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const UserDetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  return (
    <UserDetailsWrapper>
      <h4>Contact Details</h4>
      <InputLabel htmlFor="name">Name</InputLabel>
      <InputField
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <InputLabel htmlFor="email">Email</InputLabel>
      <InputField
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputLabel htmlFor="address">Address</InputLabel>
      <InputField
        id="address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
    </UserDetailsWrapper>
  );
};

const UserDetailsWrapper = styled.div`
  margin-right: 2rem;
  width: 100%;
  h2 {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
`;
const CheckoutWrapper = styled.div`
  width: 90vw;
  display: flex;
  justify-content: center;
  gap: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`;
export default CheckoutPage;
